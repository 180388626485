<template>
    <div class="serach-block">
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="itemName" placeholder="请输入物料名称" />
            <a-input v-model:value="purchaseOrderCode" placeholder="请输入采购订单号" />
            <a-input v-model:value="importNo" placeholder="请输入作业单号" />
        </a-space>
        <a-space direction="horizontal" :size="8">
            <a-button type="primary" @click="search">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
export default defineComponent({
    emits: ["search"],
    setup(props, { emit }) {
        const state = reactive({
            itemName: undefined,
            importNo: undefined,
        });

        const reset = () => {
            state.itemName = undefined;
            state.purchaseOrderCode = undefined;
            state.importNo = undefined;
            search();
        };

        const search = () => {
            let { itemName, purchaseOrderCode, importNo } = state;
            state.itemName = itemName?.trim();
            state.purchaseOrderCode = purchaseOrderCode?.trim();
            state.importNo = importNo?.trim();
            emit("search", state);
        };

        search();

        return {
            ...toRefs(state),
            search,
            reset,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-block {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .serach-space {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ant-input,
        .ant-select {
            width: 160px;
        }
    }
}
</style>
