<template>
    <a-modal v-model:visible="visible" title="数据导入" @cancel="handleCancel" :footer="null">
        <a-spin :spinning="spinning" tip="正在上传...">
            <a-upload-dragger
                accept=".xlsx"
                v-model:fileList="fileList"
                name="file"
                :showUploadList="false"
                :action="`${baseURL}${uploadUrl}`"
                :headers="headers"
                @change="handleChange"
            >
                <p class="ant-upload-text">点击或拖拽文件至此处上传</p>
                <span class="tips">请上传.xlsx后缀文件</span>
            </a-upload-dragger>
        </a-spin>
        <p style="margin-top: 10px">
            <span style="color: gray; font-size: 13px">
                上传之前请下载模版，按照格式填入数据，符合要求的数据会被正确录入，不符合的上传完成后请点下载按钮
                查看错误信息后再次上传。
                <div v-show="tips">({{ tips }})</div>
            </span>
        </p>
        <p style="margin-top: 10px">
            <a @click="handleDownLoad">模版下载</a>
        </p>
    </a-modal>
</template>
<script>
import { defineComponent, ref, toRefs, watch, reactive } from "vue";
import { ToTopOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import { staticURL, baseURL } from "@/utils/config";
import { useStore } from "vuex";
import { showMessage } from "@/utils/common";

export default defineComponent({
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        uploadUrl: {
            type: String,
        },
        downloadUrl: {
            type: String,
        },
        tips: {
            type: String,
        },
    },
    components: {
        ToTopOutlined,
    },
    setup(props, { emit }) {
        const { getters } = useStore();
        const state = reactive({
            spinning: false,
        });
        const visible = ref(false);

        const showModal = () => {
            visible.value = true;
        };

        const handleCancel = () => {
            visible.value = false;
            emit("update:visible", false);
            emit("uploadSuccess");
        };

        const handleChange = res => {
            state.spinning = true;
            if (res?.file?.status == "done") {
                const { data, msg } = res?.file?.response;
                if (data) {
                    setTimeout(() => {
                        Modal.confirm({
                            title: "提示",
                            content: msg,
                            okText: "下载",
                            cancelButtonProps: { style: { display: "none" } },
                            onOk: () => {
                                const tempFileKey = encodeURIComponent(data);
                                window.open(
                                    `${staticURL}/download-temp-file/?tempFileKey=${tempFileKey}`
                                );
                            },
                        });
                    }, 2000);
                }
                setTimeout(() => {
                    handleCancel();
                    state.spinning = false;
                    showMessage("info", `${msg}`);
                }, 2000);
            } else if (res?.file?.status == "error") {
                state.spinning = false;
                showMessage("error", "上传失败");
            }
        };

        const handleDownLoad = () => {
            window.open(`${staticURL}${props.downloadUrl}`);
        };

        watch(
            () => props.visible,
            newVal => {
                visible.value = newVal;
            }
        );

        return {
            ...toRefs(state),
            visible,
            baseURL,
            headers: {
                "mes-token": getters["user/token"],
                // "context-type": "multipart/form-data",
            },
            showModal,
            handleCancel,
            handleChange,
            handleDownLoad,
            fileList: ref([]),
        };
    },
});
</script>

<style lang="less" scoped>
.tips {
    color: gray;
}
</style>
